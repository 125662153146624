import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountDown,
} from "@fortawesome/free-solid-svg-icons";
import { actions } from "../../../context/actions";
import { useAppContext } from "../../../context";
import { ModalTypes } from "../../Modals/types";
import { Chip } from "../../Chip";
import { ChipsContainer } from ".././styles";
import { useLocalization } from "../../../hooks/useLocalization";

export const PillFiltersComponent = ({
  filters,
  sortFilterEnabled,
  getCustomizedFilterData,
  squareFilterStyling
}: {
  filters?: any[];
  sortFilterEnabled: boolean;
  getCustomizedFilterData: Function;
  squareFilterStyling?: boolean;
}) => {
  const {
    value: { productsSorting },
    dispatch,
  } = useAppContext();
  const { translation } = useLocalization();

  return (
    <>
      {filters &&
        <ChipsContainer>
          {sortFilterEnabled &&
            <Chip
              title={translation?.getLocalization?.SortAndFilter || "Sort and Filter"}
              key="Sort"
              addinitonalIcon={<FontAwesomeIcon icon={faSortAmountDown} />}
              onClick={() => {
                dispatch({
                  type: actions.TOGGLE_MODAL,
                  payload: {
                    isModal: true,
                    modalType: ModalTypes.sortAndFilter,
                  },
                });
              }}
              isExpand={true}
              colors={productsSorting.length ? "dark" : "light"}
            />
          }
          {filters.map((filterElement: any, index: number) => {
            const filterData = getCustomizedFilterData(filterElement);

            return <Chip
              key={filterData.key}
              title={filterElement.Name}
              colors={filterData.colors}
              onClick={() => {
                filterData.clickHandler();
              }}
              isExpand={false}
              squareFilterStyling={squareFilterStyling}
            />
          }
          )}
        </ChipsContainer>
      }
    </>
  );
};