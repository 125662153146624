import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_BRANDPAGE_BY_ID } from '../../queries';
import { useAppContext } from '../../context';
import { actions } from "../../context/actions";
import { ModalTypes } from "../../components/Modals/types";

export const useBrandRoute = () => {
  const { dispatch } = useAppContext();
  const { brand, productId } = useParams();

  const fullpath = '/Brand Pages/' + brand
  const { data, loading } = useQuery(GET_BRANDPAGE_BY_ID,
    {
      variables: {
        fullpath: fullpath,
      },
    }
  );

  React.useEffect(() => {
    if (productId) {
      if (!loading) {
        dispatch({
          type: actions.TOGGLE_MODAL,
          payload: {
            isModal: true,
            modalType: ModalTypes.productOverview,
            modalContent: {
              product: { id: productId },
            },
          },
        });
      }
    }
  }, [productId, dispatch, loading]);

  return {
    loading,
    data,
  };
};
