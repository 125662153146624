import React from 'react';

import { ProductOverviewModalStyled } from './styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../../Button';
import { ProductDetails } from './components/ProductDetails';
import { ProductOverview } from './components/ProductOverview';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { IProduct } from '../../../../types';
import { useCartHook } from '../../../../hooks/useCartHook';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_CATEGORY_BY_TERM,
} from '../../../../queries';
import { Instagram } from 'react-content-loader';
import { ErrorPage } from '../../../ErrorPage';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../../../context';
import { actions } from '../../../../context/actions';
import { ProductsSlider } from './components/SimilarProducts';
import { useLocalization } from '../../../../hooks/useLocalization';

export const ProductOverviewModal = ({
  product,
  route,
}: {
  product: IProduct;
  route: boolean;
}) => {
  const { handleCart } = useCartHook();
  const {
    value: { isModal },
    dispatch,
  } = useAppContext();
  const {translation}=useLocalization();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { categoryId, storeId, harvestId, termId, productId, brand } = useParams();
  const [AddToCart, setAddToCart] = React.useState('');

  const { data, loading } = useQuery(GET_PRODUCT_BY_ID, {
    variables: {
      getEPogId: parseFloat(product.id),
    },
  });
  const getReturnUrl = () => {
    const baseUrl = (storeId ? `/${storeId}/${harvestId}` : `/${harvestId}`);

    if (pathname.includes('/search/'))
      return `${baseUrl}/search/${termId}`
    if (pathname.includes('/brand/'))
      return `${baseUrl}/brand/${brand}`
      
    return `${(storeId ? `/${storeId}` : `/`)}`
  }

  const [handlePDPButton, { data: categoryData, loading: categoryLoading }] =
    useLazyQuery(GET_PRODUCT_CATEGORY_BY_TERM({ termId }));

  React.useEffect(() => {
    if (termId) {
      handlePDPButton();

      if (!categoryLoading) {
        const PDPAddToCart =
          categoryData &&
          categoryData.getEPogTermListing &&
          categoryData.getEPogTermListing.edges &&
          categoryData.getEPogTermListing.edges[0].node.PDPAddToCartButton;

        if (PDPAddToCart) {
          setAddToCart(PDPAddToCart);
        }
      }
    }
  }, [handlePDPButton, categoryData, categoryLoading, termId]);

  React.useEffect(() => {
    //
    // Switch off modal when no id param
    //
    if (!route) {
      if (!productId)
        dispatch({
          type: actions.TOGGLE_MODAL,
          payload: { isModal: false, modalContent: null },
        });

      //
      // On modal close remove ID param from router
      //

      if (!isModal) {
        return () => navigate(getReturnUrl());
      }
    }
  }, [
    navigate,
    termId,
    productId,
    harvestId,
    dispatch,
    isModal,
    pathname,
    route,
  ]);

  if (loading) {
    return <Instagram />;
  } else if (!loading && !data) {
    return <ErrorPage />;
  } else {
    product = data.getEPog;

    const { RegularPrice, Price, Description, Upsel } = product;


    return (
      <ProductOverviewModalStyled>
        <ProductOverview product={product} urlParams={{storeId,harvestId}}/>
        <div className="line-break"></div>
        <div className="more-sellers">
          <strong>{translation?.getLocalization?.MoreSellerOptions || "More seller options"} (15)</strong>
          <p>{translation?.getLocalization?.StartingFrom || "Starting from"} ${Price?.toFixed(2)}</p>
          <p>
            <u>{translation?.getLocalization?.CompareAllSellers || "Compare all sellers"}</u>
          </p>
        </div>
        <div className="line-break"></div>
        {Upsel && (
          <ProductsSlider
            products={Upsel}
            title={translation?.getLocalization?.SimilarItemsYouMightLike || "Similar items you might like"}
            subTitle={translation?.getLocalization?.BasedOnWhatCustomersBought || "Based on what customers bought"}
          />
        )}
        <div className="line-break"></div>
        <ProductDetails details={Description} />
        <div className="line-break"></div>
        {/* <CustomerReviews product={product} /> */}
        <div className="line-break"></div>
        <div className="unusual-activity">
          <FontAwesomeIcon icon={faComment} />
          <span>
            <strong>{translation?.getLocalization?.NoticeUnusualMarketplaceActivity || "Notice unusual marketplace activity?"}</strong>
          </span>
          <span>
            <u>{translation?.getLocalization?.Report || "Report"}</u>
          </span>
        </div>
        <div className="line-break"></div>
        <div className="feedback">
          <span>{translation?.getLocalization?.WedLoveToHear || "We'd love to hear what you think!"}</span>
          <span>
            <u>{translation?.getLocalization?.GiveFeedback || "Give Feedback"}</u>
          </span>
        </div>
        <div className="footer">
          <span>
          {product.Price ?
            <>${product.Price.toFixed(2)}<span className='oldPrice'>${product.RegularPrice?.toFixed(2)}</span></>
            :
            <>${product.RegularPrice?.toFixed(2)}</> 
          }
          </span>
          <Button
            variant="add"
            onClick={handleCart}
            content={{
              text: AddToCart ? AddToCart : (translation?.getLocalization?.AddToCart || 'Add to cart'),
              product,
            }}
            url={{categoryId, storeId, harvestId}}
          />
        </div>
      </ProductOverviewModalStyled>
    );
  }
};
