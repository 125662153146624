import { gql } from '@apollo/client';
import { CATEGORY_FRAGMENT, PRODUCT_FRAGMENT } from './fragments';

export const GET_PRODUCT_CATEGORY_BY_TERM = ({ termId }: any) => {
  return gql`
    ${PRODUCT_FRAGMENT}
    {
      getEPogTermListing(ids: \"${termId}\" ) {
        edges {
          node {
            key
            Name
             BrandFilters{
            ... on fieldcollection_Brand {
              BrandName
            }
          }
            Products {
              ...Product
              ... on object_ePog {
                Variants {
                  ...Product
                }
                VariantsByType {
                  ... on fieldcollection_VariantsByType {
                    VariantType
                    ProductVariants {
                        ...Product
                    }
                  }
                }
                Upsel {
                  ... Product
                }
              }
              ...on object_ListPromoContent {
                Video {
                  title
                  data {
                    ...on asset {
                      fullpath
                    }
                  }
                }
              }
            }
            GenericFilters {
                ... on fieldcollection_Brand {
                BrandName
              }
            }
            PillFilters{
              ... on object_ProductField {
                Name
              }
            }
            PillFiltersWithImage{
              ... on object_PillFilter {
                Name
                Thumbnail {
                  fullpath
                }
              }
            }
            AddProductButton
            OptionsButton
            PDPAddToCartButton
            TermBannerImage { 
              fullpath
            }
            TermBannerVideo {
              data {
                ...on asset {
                  fullpath
                }
              }
            }
            TermBannerText
            TermBannerVideoPosition
            TermBannerProducts{
          ... on object_ePog{
            id
            key
            Price
            Name
            RegularPrice
            RRAmount
            RROverall
            Gallery{
              image{
                fullpath
              }
            }
          }
        }
            TermBannerSponsoredBy
             children {
             ... on object_ePogTerm {
            key
            id
            fullpath
            key
          }
        }
          }
        }
      }
    }
  `;
};

export const SEARCH_CATEGORIES_BY_NAME = (searchTerm: any) => gql`
${PRODUCT_FRAGMENT}
  {
    getEPogTermListing(
      filter: "{\\"key\\": {\\"$like\\" :\\"%${searchTerm}%\\"}}"
    ) {
      edges {
        node {
          key
          Products {
           ...Product
           ... on object_ePog {
               Variants {
            ... Product
             }
             Upsel {
            ... Product
             }
            }
          }
        }
      }
    }
  }
`;

export const GET_BRANDPAGE_BY_ID = gql`
query GetBrandPage($fullpath: String) {
  getBrandPage(fullpath: $fullpath) {
    Title
    TopContent
    Products {
		... on object_ePog {
        key
        Name
        Gallery {
          image {
            fullpath
          }
        }
        id
        Price
        RegularPrice
      }
    }
    BottomContent
  }
}
`;

export const GET_PRODUCTS = gql`
  ${PRODUCT_FRAGMENT}
  query Query($first: Int) {
    getEPogListing(first: $first) {
      edges {
        node {
          ...Product
          Variants {
            ...Product
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_BY_ID = gql`
  ${PRODUCT_FRAGMENT}

  query GetEPog($getEPogId: Int) {
    getEPog(id: $getEPogId) {
      ...Product
      ... on object_ePog {
        Variants {
          ...Product
        }
        Upsel {
          ...Product
        }
      }
    }
  }
`;

export const LIST_ROOT_CATEGORIES = gql`
  query GetObjectFolder($fullpath: String) {
    getObjectFolder(fullpath: $fullpath) {
      children {
        ... on object_ePogTerm {
          key
          fullpath
          id
        }
      }
    }
  }
`;

export const GET_CAROUSEL_ITEMS = gql`
  query Query {
    getCarouselItemListing {
      edges {
        node {
          Content
          Image {
            fullpath
          }
          Title
          ButtonLabel
        }
      }
    }
  }
`;

export const GET_HARVEST_TERMS = gql`
  ${CATEGORY_FRAGMENT}

  query GetHarvestTerms($fullpath: String) {
    getHarvest(fullpath: $fullpath) {
      children {
        ...RecursiveCategory
      }
    }
  }
`;

export const GET_HOME_PAGE_BY_ID = gql`
query GetBrandPage($fullpath: String) {
    getHomePage(fullpath: $fullpath) {
      id
      Styles
      Elements {
        ... on fieldcollection_HomePageElementRT {
          __typename
          Content
        }
        ... on fieldcollection_HomePageElementCarousel {
          __typename
          Title
          CarouselItems {
            ... on object_CarouselItem{
              Title
              Content
              ButtonLabel
              Image {
                fullpath
              }
            }
          }
        }
        ... on fieldcollection_HomePageElementSlider {
          __typename
          Title
          SliderProducts{
          ... on object_ePog {
              key
              Name
              Gallery {
                image {
                  fullpath
                }
              }
              id
              Price
              RegularPrice
            }
          }
        }
      }
    }
  }
`;

export const GET_CONFIGURATION = gql`
  query GetConfiguration($fullpath: String) {
    getHarvest(fullpath: $fullpath) {
      id
      Template {
        ... on object_SiteTemplate {
          ProductFields {
            ... on object_ProductField {
              Name
            }
          }

          ShipmentMethod {
            ... on object_ProductField {
              Name
            }
          }
          HomePageTemplate {
            ... on object_HomePage {
              fullpath
            }
          }
          LanguageVersion

          id
          ShipmentMethods
          FilterType
          PillFiltersWithImageDisplayStyle
          SortByEnable
          Columns
          Rows
        }
      }
    }
  }
`;

export const GET_LOCALIZED = gql`
  query getLocalization($defaultLanguage: String) {
    getLocalizationAmzWm(
      fullpath: "/Translations/TranslationsAmzWm"
      defaultLanguage: $defaultLanguage
    ) {
      Sponsored
      StartShopping
      YourCartIsEmpty
      GiveFeedback
      AllOfWalmart
      WedLoveToHear
      CheckoutAllItems
      AvailableForPickup
      ShippingTo
      WantItAsSoonAsToday
      SwitchToPickup
      HowFastDoYouWantYourOrder
      Anytime
      Today
      Tomorrow
      TwoDays
      HowDoYouWantYourOrder
      MoreSellerOptions
      StartingFrom
      CompareAllSellers
      Report
      CustomerReviews
      MoreDetails
      Reviews
      BestSeller
      Pick
      SoldAndShippedBy
      Free90DayReturns
      Details
      SortAndFilter
      PopularServices
      ViewAll
      Pharmacy
      AutoCareCenter
      CustomCakes
      Add
      Pickup
      TwoDayShipping
      ResetFilters
      ProductVariants
      BuildYourCart
      Trending
      SimilarItemsYouMightLike
      BasedOnWhatCustomersBought
      NoticeUnusualMarketplaceActivity
      WellBringItToYou
      GetYourOrderShippedFast
      PriceRange
    }
    getLocalization(
      fullpath: "/Translations/Translations"
      defaultLanguage: $defaultLanguage
    ) {
      Items
      BrandProducts
      OpenDetails
      HideDetails
      SoldBy
      Remove
      SaveForLater
      ContinueToCheckout
      Subtotal
      Taxes
      CalculatedAtCheckout
      EstimatedTotal
      AdditionalInfoHere
      ProductDetails
      CustomersAlsoConsidered
      RecommendedForYou
      PairWellTogether
      CustomerReviewsRatings
      AllReviews
      SomethingWentWrong
      TryAgain
      Clear
      ViewResults
      BrandsAreYouInterestedIn
      CategoriesAreYouInterestedIn
      YourPriceRange
      SpecialOffersAreYouInterestedIn
      Variants
      AllFilters
      Reset
      Update
      AllRightsReserved
      StoreLocations
      Departments
      Services
      SignIn
      GetItInDays
      GetItIn2Hours
      GetItIn3Hours
      AddToList
      ShippingMethod
      ActualVariant
      OutOf
      Reviewers
      SeeAllReviews
      WriteAReview
      SortBy
      Rating
      VerifiedPurchaser
      PopularFilters
      Options
      ResultsFor
      PopularProducts
      PopularIn
      PopularSections
      Deals
      NewProducts
      SearchInStore
      Price
      Brand
      Categories
      SpecialOffers
      CartIsEmpty
      Cart
      ShowProductWithVariants
      ShowProductWithNoVariants
      FourOrMore
      ThreeOrMore
      TwoOrMore
      OneOrMore
      Sort
      AlphabetAZ
      AlphabetZA
      PriceLH
      PriceHL
      RatingLH
      RatingHL
      FooterLinks
      AddToCart
      AboutThisItem
      Stars
      None
      ArrivesBy
      ShippingArrives
      UpdateCart
      CheckoutMessage
      ReviewCart
      Search
    }
  }
`;