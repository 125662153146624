import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDollarSign,
  faSortAmountDown,
} from "@fortawesome/free-solid-svg-icons";
import { actions } from "../../context/actions";
import { useAppContext } from "../../context";
import { ModalTypes } from "../Modals/types";
import { Chip } from "../Chip";
import { FiltersStyled } from "./styles";
import { useParams } from "react-router-dom";
import { IProduct } from "../../types";
import { useFilters } from "./useFilters";
import ResetFiltersButton from "../ResetFiltersButton/ResetFiltersButton";

export const Filters = ({
  products,
  GenericFilters,
}: {
  products: IProduct[];
  GenericFilters: string[];
}) => {
  const {
    value: { productsSorting },
    dispatch,
  } = useAppContext();

  const { translation, isPriceFilter, isBrandsFilter, handlePriceRangeContext } = useFilters(
    {
      products,
    }
  );

  const { termId } = useParams();


  return (
    <FiltersStyled>
      <ResetFiltersButton dispatch={dispatch}/>
      <div>
        <Chip
          title={translation?.getLocalization?.SortAndFilter || "Sort and Filter"}
          addinitonalIcon={<FontAwesomeIcon icon={faSortAmountDown} />}
          onClick={() => {
            dispatch({
              type: actions.TOGGLE_MODAL,
              payload: {
                isModal: true,
                modalType: ModalTypes.sortAndFilter,
              },
            });
          }}
          isExpand={true}
          colors={productsSorting.length ? "dark" : "light"}
        />
        <Chip
          title={translation?.getLocalization?.PriceRange || "Price-range"}
          addinitonalIcon={<FontAwesomeIcon icon={faDollarSign} />}
          colors={isPriceFilter ? "dark" : "light"}
          onClick={() => {
            handlePriceRangeContext();
            dispatch({
              type: actions.TOGGLE_MODAL,
              payload: {
                isModal: true,
                modalType: ModalTypes.priceRange,
              },
            });
          }}
          isExpand={true}
        />
        <Chip
          title={translation?.getLocalization?.Brand || "Brand"}
          onClick={() => {
            dispatch({
              type: actions.TOGGLE_MODAL,
              payload: {
                isModal: true,
                modalType: ModalTypes.brands,
                modalContent: termId,
              },
            });
          }}
          colors={isBrandsFilter ? "dark" : "light"}
          isExpand={false}
        />
        {GenericFilters &&
          GenericFilters.map((filter, index) => (
            <Chip title={filter} key={index} onClick={() => {}} />
          ))}
      </div>
    </FiltersStyled>
  );
};
