import React from 'react';

import styled from '@emotion/styled';

import parse from "html-react-parser";
import { Carousel } from '../../components/Carousel';
import { Header, HeaderVariants } from '../../components/Header';
import { ProductsSlider } from '../../components/Modals/variants/ProductOverviewModal/components/SimilarProducts';
import { ShopLocation } from '../../components/ShopLocation';
import { Feedback } from '../../components/Feeback';
import { FavoriteDepartments } from '../../components/FavoriteDepartments';
import { useCategoriesHook } from '../../hooks/useCategoriesHook';
import { useMainRoute } from './useMainRoute';
import { useParams } from 'react-router-dom';
import { Instagram } from 'react-content-loader';

export const MainRoute = () => {
  const { harvestId } = useParams();
  const { categories, categoriesLoading }: any =
    useCategoriesHook({ harvestId, numberOfItems: 8 }) || [];

  const { /*carouselItems, products, translation,*/ homePage }:{homePage:any} = useMainRoute() || [];

  if (categoriesLoading) {
    return <Instagram />;
  } else {
    return (
      <RouteStyled>
        <Header variant={HeaderVariants.home} />
        <ShopLocation
          shopName="Secaucus Supercenter"
          shopLocation="Bronx 10451"
        />
        {categories && (
          <div>
            <FavoriteDepartments categories={categories} />
          </div>
        )}
        {homePage && (
          <style>{homePage.Styles}</style>
        )}
        {
          homePage && (
            homePage.Elements.map((item: any, index: number) => {
              if (item.__typename == 'fieldcollection_HomePageElementSlider') {
                return (<ProductsSlider products={item.SliderProducts} title={item.Title}></ProductsSlider>);
              }
              if (item.__typename == 'fieldcollection_HomePageElementCarousel') {
                return (<div className="carousel-container">
                  <Carousel items={item.CarouselItems}></Carousel>
                </div>);
              }
              if (item.__typename == 'fieldcollection_HomePageElementRT') {
                return (<div className='top'>
                  {parse(item.Content)}
                </div>);
              }
            })
          )
        }
        <Feedback />
      </RouteStyled>
    );
  }
};

const RouteStyled = styled.div`
  .content {
    padding: 15px;
  }
  .free-trial {
    margin-top: 32px;
  }
`;
