import React from "react";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from "@emotion/styled";

type Tcolors = string | (() => string);

interface IProps {
  title: string;
  onClick: () => void;
  isExpand?: boolean;
  addinitonalIcon?: React.ReactNode;
  colors?: Tcolors;
  squareFilterStyling?: boolean;
}

export const Chip = ({
  title,
  onClick,
  isExpand,
  addinitonalIcon,
  colors,
  squareFilterStyling,
}: IProps) => {
  return (
    <ChipStyled onClick={onClick} colors={colors} squareFilterStyling={squareFilterStyling}>
      {addinitonalIcon && (
        <span className="additional-icon">{addinitonalIcon}</span>
      )}
      {title}
      {isExpand && <FontAwesomeIcon className="expand" icon={faChevronDown} />}
    </ChipStyled>
  );
};

const ChipStyled = styled.span<{ colors?: Tcolors; squareFilterStyling?:boolean }>`
  font-size: 1.5rem;
  font-weight: 300;
  background: ${({ colors, squareFilterStyling }) => {
    if (squareFilterStyling) return colors === "dark" ? "#000" : "#fff"
    else return colors === "dark" ? "#000" : "#efeff0"
  }};
  color: ${({ colors }) => (colors === "dark" ? "#fff" : "#000")};
  border-radius: ${({ squareFilterStyling }) => (squareFilterStyling ? "0" : "20px")};
  padding: 10px 20px;
  margin: 0 5px;
  display: inline-flex;
  align-items: center;
  border: ${({ squareFilterStyling }) => (squareFilterStyling ? "1px solid #e5e5e5" : "0")};

  .additional-icon {
    margin-right: 6px;
    font-size: 1.1rem;
  }
  .expand {
    margin-left: 7px;
  }
`;
