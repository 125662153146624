import { IProduct } from "../../types";

export const handleFiltering = ({
  products,
  searchFilters,
}: {
  products: IProduct[];
  searchFilters: any;
}) => {
  let filteredArray = [...products];
  let tempArray: any;
  let finArray: any;

  if (searchFilters.length) {
    searchFilters.forEach((searchFilter: any) => {
      if (searchFilter.name === "Price") {
        const {
          userSelectedPriceFilterMin,
          productDatabaseMinPrice,
          userSelectedPriceFilterMax,
          productDatabaseMaxPrice,
        } = searchFilter;

        const min = userSelectedPriceFilterMin || productDatabaseMinPrice;
        const max = userSelectedPriceFilterMax || productDatabaseMaxPrice;

        filteredArray = filteredArray.filter(({ RegularPrice, Price }) => {
          const price = RegularPrice ? RegularPrice : Price;
          return price >= min && price <= max;
        });
      }
      if (searchFilter.name === "Brand") {
        const brandsToFilter = Object.entries(searchFilter.brands)
          .map(([key, value]) => value && key)
          .filter((brand: any) => typeof brand !== "boolean");

        if (brandsToFilter.length) {
          filteredArray = filteredArray.filter((product: IProduct) =>
            brandsToFilter.includes(product.Brand)
          );
        }
      }
      if (searchFilter.name === 'Categories') {
        const categoriesToFilter = Object.entries(searchFilter.categories)
          .map(([key, value]) => value && key)
          .filter((SubCategory: any) => typeof SubCategory !== 'boolean');

        if (categoriesToFilter.length) {
          filteredArray = filteredArray.filter((product: IProduct) =>
          categoriesToFilter.includes(product.SubCategory)
          );
        }
      }
      if (searchFilter.name === 'Rating') {
        if (searchFilter.value) {
          filteredArray = filteredArray.filter(
            (product: IProduct) => product.RROverall >= searchFilter.value
          );
        }
      }
      if (searchFilter.name === 'Variants') {
        if (searchFilter.value) {
          if (searchFilter.value === 'yes') {
            filteredArray = filteredArray.filter((product: IProduct) => {
              const byTypeArr: any = product?.VariantsByType?.flat();
              return product?.Variants?.length > 0 || byTypeArr?.length > 0;
            });
          } else {
            filteredArray = filteredArray.filter((product: IProduct) => {
              const byTypeArr: any = product?.VariantsByType?.flat();
              return !product?.Variants?.length && !byTypeArr?.length;
            });
          }
        }
      }
      if (searchFilter.name === 'Special Offers') {
        const specialOffersToFilter = Object.entries(searchFilter.promo)
          .map(([key, value]) => value && key)
          .filter((PromoType: any) => typeof PromoType !== 'boolean');

        if (specialOffersToFilter.length) {
          filteredArray = filteredArray.filter((product: IProduct) =>
          specialOffersToFilter.includes(product.PromoType)
          );
        }
      }
      if (searchFilter.name.includes('Custom_')) {
        if (searchFilter.checked) {
          if (searchFilter.value) {
            tempArray = products.filter((product: IProduct) =>{
              if (product?.CustomFilterValues != null) { 
                return product?.CustomFilterValues[0].AdditionalFilterValue == searchFilter.value;
              }
            });
            if (tempArray && tempArray.length >= 1) { 
              finArray = Array.from(new Set(tempArray.concat(finArray)));
            }
          }
        }
      }

    });
  }

  if (finArray && finArray.length >= 1) { 
    filteredArray = finArray;
  }
  filteredArray = filteredArray.filter( Boolean );
  
  return filteredArray;
};

export const handleSorting = ({
  products,
  productsSorting,
}: {
  products: IProduct[];
  productsSorting: any;
}) => {
  const sortedArray = [...products];
  if (productsSorting.length) {
    productsSorting.forEach((productSort: any) => {
      if (productSort?.value?.includes('Price')) {
        sortedArray.sort(
          (
            { Price: aPrice, RegularPrice: aRegularPrice },
            { Price: bPrice, RegularPrice: bRegularPrice }
          ) => {
            const priceA = aRegularPrice ? aRegularPrice : aPrice;
            const priceb = bRegularPrice ? bRegularPrice : bPrice;
            return productSort.value === "Price:low>high"
              ? priceA - priceb
              : priceb - priceA;
          }
        );
      }

      if (productSort?.value?.includes('Rating')) {
        sortedArray?.sort(({ RROverall: aRating }, { RROverall: bRating }) => {
          return productSort.value === 'Rating:low>high'
            ? aRating - bRating
            : bRating - aRating;
        });
      }

      if (productSort?.value?.includes('Alphabet')) {
        sortedArray?.sort(({ key: aName }, { key: bName }) => {
          return productSort.value === 'Alphabet:A>Z'
            ? aName?.localeCompare(bName)
            : -1 * aName?.localeCompare(bName);
        });
      }
    });
  }
  return sortedArray;
};
